import React, { useState, useEffect } from 'react';
import '../App.css';
import './Home.css';
import BannerSlider from '../Common/Sliders/BannerSlider';
import { DiamondOutlined, LocalMallOutlined, LocalShippingOutlined, NoCrashOutlined, Star, VerifiedUserOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { loadOEbyCarCodeType, setIsSelectingVehicle } from '../reducers/vehicle';
import { subcatPeloNomeCategoria, subcatPeloNomeCategoriaBikes, idsSubcategoriaPeloNome, categoryAxle } from '../Utilities/CategoriesLists';
import { useDispatch, useSelector } from 'react-redux';
import { getFunctions, httpsCallable } from "firebase/functions";
import { defaultStore, storeSellersWarehouses } from '../Common/Breakpoints';
import Alelo from '../images/alelo.webp';
import Amex from '../images/amex.webp';
import Elo from '../images/elo.webp';
import Hipercard from '../images/hipercard.webp';
import Jcb from '../images/jcb.webp';
import Master from '../images/master.webp';
import Visa from '../images/visa.webp';
import Diners from '../images/diners.webp';
import ReclameOtimo from '../images/reclameaqui_otimo.webp';
import ReclameLogo from '../images/reclameaqui_logo.webp';
import Ssl from '../images/ssl_selo.webp';
import Lgpd from '../images/LGPD_small.webp';
import Pix from '../images/pix_logo.webp';
import GoogleWhite from '../images/google.webp';
import { BrandsListSVG, HomologBrandsSVG } from '../Utilities/BrandsList';
import GoParca_Show_01 from '../images/artwork/goparca_01_300_02_tall_nardo.webp';
import GoParca_Show_02 from '../images/artwork/goparca_01_200_03_tall_nardo.webp';
import GPlogoPatternBG_01 from '../images/GoParts_Logos_Pattern_01.jpg';
import Button from '../Common/Button';
import WhatsappIcon from "@mui/icons-material/WhatsApp";
import { CategoriesPH, storeInfo } from '../Utilities/Placeholders';
import { categoriesCars, categoriesBikes, bikeBrands } from '../Utilities/CategoriesLists';
import CategoryTile from '../Common/Tiles/CategoryTile';
import GoogleMaps_bg from  '../images/googleMaps_bg.jpg';
import Whataspp_bg from  '../images/whatsapp_BG_small.webp';
import GoParca_Zen from '../images/artwork/goparca_03_full_mirror_zen_nardo.webp';
import GoParca_Zen_2 from '../images/artwork/goparca_03_full_zen_nardo.webp';
import antifraudIcon from '../images/Icone_Antifraude.png';
import brakesImg02 from '../images/brake_02.webp';
import oils_01 from '../images/oils_01.webp';
import engine_01 from '../images/engine_01.webp';
import oilKit_01 from '../images/oilKit_01.webp';
import roadBg_01 from '../images/home_road_bg_01.jpg';
import allCats from '../images/categories/allCats.webp';
import { handleWhatsappClick } from '../Utilities/Whatsapp';
import { contentData, configData } from '../Whitelabel/websitesData';
import ProductSlider from '../Common/Sliders/ProductSlider';
import ReclameAquiSeal from '../Common/ReclameAqui';
import MotorPerifericosImg from '../images/categories/motoreperifericos_500px.webp';
import LubrificantesFluidosImg from '../images/categories/lubrificantes_e_fluidos_500px.webp';
import FiltrosImg from '../images/categories/filtros_500px.webp';
import FreiosImg from '../images/categories/freios_500px.webp';

import BremboLogo from '../images/brands/brembo_white.png';
import AcdelcoLogo from '../images/brands/acdelco_color.png';

import umuaramaHdBg01 from '../images/UmuaramaHD/UHD_banner_home_01.webp';
import UHD_boutique_bg_01 from '../images/UmuaramaHD/UHD_boutique_bg_01.webp';
import UHD_pecas_bg_01 from '../images/UmuaramaHD/UHD_pecas_bg_01.webp';
import UHD_acessorios_bg_01 from '../images/UmuaramaHD/UHD_acessorios_bg_01.webp';
import UHD_offer_jacket_01 from '../images/UmuaramaHD/UHD_offer_jacket_01.webp';
import UHD_offer_helmet_01 from '../images/UmuaramaHD/UHD_offer_helmet_01.webp';
import UHD_offer_jacket_00 from '../images/UmuaramaHD/UHD_offer_jacket_00.webp';
import UHD_offer_helmet_00 from '../images/UmuaramaHD/UHD_offer_helmet_00.webp';

function Home() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedCat, setSelectedCat] = useState(null);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initialDataLoading, setInitialDataLoading] = useState(true);
    const selectedCar = useSelector(state => state.vehicle.selectedVehicle);
    const { selectedBrand, isSelectingVehicle, selectedYear, selectedModel, selectedVersion } = useSelector(state => state.vehicle);
    const internalID = selectedCar?.InternalID;
    const allOEs = useSelector(state => state.vehicle.carOEs);
    const [newLink, setNewLink] = useState('');
    const Categories = (selectedBrand && bikeBrands.includes(selectedBrand.toLowerCase())) ? categoriesBikes : categoriesCars;

    const CardOperators = [
        Master,
        Visa,
        Amex,
        Hipercard,
        Jcb,
        Elo,
        Alelo,
        Diners,
        Pix,
    ];

    const { title, content, link } = storeInfo.aboutUs;

    const handleAboutUsClick = () => {
        window.open(`${link}`, '_blank');
    }

    const openVehicleSelect = () => {
        if (selectedVersion) {
          navigate('/categorias');
        } else {
          dispatch(setIsSelectingVehicle(!isSelectingVehicle));
        }
    };

    const whatsappInfo = 'De segunda à sexta, das 8h às 18h.'

    useEffect(() => {
        if((internalID !== undefined) && (internalID !== "")) {
            dispatch(loadOEbyCarCodeType(internalID)).then(() => {
                setInitialDataLoading(false);
            });
        } else {
            setInitialDataLoading(false);
        }
    }, [dispatch, internalID]);

    useEffect(() => {
        if (selectedCat && products.length !== 0) {
            setLoading(false);
            navigate(`/produtos/${selectedCat.name}`, 
                {
                    state: {
                        products: products,
                        image: selectedCat.image
                    }
                }
            );
        }
    }, [selectedCat, navigate, products]);

    const categoryClick = async (clickedCat, image) => {
        if(!selectedVersion) {
            navigate(`/produtos/${clickedCat}`,
                {
                    state: {
                        image: image
                    }
                }
            );
        } else {
            //console.log('selectedVersion: ', selectedVersion);
            setLoading(true);
            const categoryIds = getCategoriesIds(clickedCat);
            
            const category = {
                name: clickedCat,
                image: image
            };
        
            // Check if allOEs and allOEs.parts are defined
            if (allOEs?.parts && allOEs.parts.length > 0) {
                try {
                    await loadParts(selectedCar, categoryIds, true, "all", allOEs);
                    setSelectedCat(category);
                } catch (error) {
                    console.error('Error loading parts:', error);
                }
            } else {
                console.error('allOEs or allOEs.parts is not defined or empty');
                setLoading(false); // Stop loading if allOEs is not defined
            }
        }
    };
    
    const getCategoriesIds = (name) => {
        const subCategories = bikeBrands.includes((selectedBrand || '').toLowerCase()) ? subcatPeloNomeCategoriaBikes[name] : subcatPeloNomeCategoria[name];
        const listofIds = [];
        subCategories.forEach((element) => {
            listofIds.push(...idsSubcategoriaPeloNome[element]);
        });
    
        return listofIds;
    };
    
    const loadParts = async (car, ids, starting, subcategory, allOEs) => {
        const partsList = [];
    
        ids.forEach((categoryId) => {
            // Check if allOEs.parts is defined before using it
            allOEs.parts?.forEach((oe) => {
                if (oe.id_prod === categoryId.toString()) {
                    if (!partsList.includes(oe.oe_gpm)) partsList.push([oe.oe_gpm]);
                }
            });
        });
    
        const distinctIds = partsList;
    
        const axle = categoryAxle[subcategory.toString()];
    
        try {
            const WHparts = await getPartsListFromWH({
                data: distinctIds,
                axle: axle,
                cross: false,
                carID: car.InternalID.toString()
            });
    
            setProducts(WHparts);
        } catch (error) {
            console.error('Error loading parts:', error);
        } finally {
            setLoading(false); // Ensure loading state is reset even if there's an error
        }
    };

    const getPartsListFromWH = async (info) => {
        const functions = getFunctions();
        const callableGetPartsListFromWH = httpsCallable(functions, 'getPartsListFromWH');
    
        const parts = await callableGetPartsListFromWH(info).then((result) => {
            let i = 0;
            const listofArticles = [];
            result.data.forEach((document) => {
                i = i + 1;
                if (document != null) {
                    const listOfSellers = document["sellersList"];
                    const growableList = [];
                    const storeprice = defaultStore === "GoParts" ? "price_ecommerce_gp" : "price_ecommerce_seller";

                    listOfSellers.forEach((element) => {
                        if (storeSellersWarehouses.includes(element)) {
                            growableList.push({
                                id: document["id"].toString(),
                                active: document[element]["active"],
                                name: document[element]["name"],
                                price: document[element]["price"],
                                stock: document[element]["stock"],
                                price_ecommerce: document[element].hasOwnProperty(storeprice)
                                ? document[element][storeprice]
                                : document[element]["price"],
                                distance: 1
                            });
                        }
                    });

                    growableList.sort((a, b) => {
                        var mina = a.price_ecommerce < a.price ? a.price_ecommerce : a.price;
                        var minb = b.price_ecommerce < b.price ? b.price_ecommerce : b.price;

                        if (mina < minb) return -1;

                        if (minb < mina) return 1;
                        return 0;
                    });

                    listofArticles.push({
                        id: document['id'],
                        active: document['active'],
                        description: document['desc'],
                        ds_number: document['ds_number'],
                        ds_number_gp: document['ds_number_gp'],
                        id_prod: document['id_prod'],
                        id_supplier: document['id_supplier'],
                        images: document['imhash'],
                        listofPropertiesbyCar: document.hasOwnProperty('prop_car')
                            ? document['prop_car']
                            : null,
                        priority: document['priority'],
                        ds_numbers: document['ds_numbers'],
                        weight: document.hasOwnProperty('pesoG')
                            ? document['pesoG']
                            : -1,
                        height: document.hasOwnProperty('altCM')
                            ? document['altCM']
                            : -1,
                        depth: document.hasOwnProperty('profCM')
                            ? document['profCM']
                            : -1,
                        width: document.hasOwnProperty('largCM')
                            ? document['largCM']
                            : -1,
                        sellers: growableList,
                    });
                }
            });

            if (listofArticles.length === 0) {
                listofArticles.push({
                    id: "0",
                    active: false,
                    description: "Sem cadastro de peça",
                    ds_number: "Sem cadastro de peça",
                    ds_number_gp: "Sem cadastro de peça",
                    id_prod: "Sem cadastro de peça",
                    id_supplier: "0",
                    images: [],
                    sellers: [],
                    listofPropertiesbyCar: {},
                    priority: 0,
                    weight: -1,
                    height: -1,
                    depth: -1,
                    width: -1,
                    ds_numbers: [],
                });
            }

            listofArticles.sort((a, b) => {
                const totalA = a.sellers.reduce((tot, item) => tot + Number(item.stock), 0);
                const totalB = b.sellers.reduce((tot, item) => tot + Number(item.stock), 0);
                return totalB - totalA;
            });

            return listofArticles;
        }).catch((error) => {
          console.error(error);
        });

        return parts;
    };

    const topMargin = configData.defaultStore === 'UmuaramaHD' ? '410px' : '0';

    const navigateToCategory = (categoriaHD) => {
        //console.log('cat click!');

        if(configData.defaultStore === 'UmuaramaHD'){
            navigate(`/produtos/${categoriaHD}`)
        } else {
            navigate('/categorias');
        }
    }

    return (
        <div className="main fadeIn homeStyle">
            {configData.defaultStore === 'UmuaramaHD'
                ?
                    <div className='staticBannerContainer' style={{width: '100%', backgroundImage: `url(${umuaramaHdBg01})`}}></div>
                :
                    <BannerSlider/>
            }

            {/* <ProductSlider products={contentData.highlightProducts['Boutique']} /> */}

            {configData.defaultStore === 'UmuaramaHD' &&
                <>
                    <div className='homeSectionContainer' style={{marginTop: `${topMargin}`}}>
                        <div className='rowToColumnNoGap' style={{maxWidth: '1280px', width: '100%', justifyContent: 'start'}}>
        
                            <div className='homeSectionContainer center homeSectonsBorder UHDcatContainer' style={{backgroundImage: `url(${UHD_boutique_bg_01})`, alignItems: 'start'}} onClick={() => navigateToCategory("Boutique")}>
                                <div className='catText' style={{justifyContent: 'end'}}>
                                    <h1 style={{fontSize: '24px'}}>Boutique</h1>
                                    <span style={{color: '#FFCD9D', fontSize: '16px'}}>Melhores ofertas</span>
                                </div>
                            </div>
        
                            <div className='homeSectionContainer center homeSectonsBorder UHDcatContainer' style={{backgroundImage: `url(${UHD_pecas_bg_01})`, alignItems: 'start'}} onClick={() => navigateToCategory("Peças")}>
                                <div className='catText' style={{justifyContent: 'end'}}>
                                    <h1 style={{fontSize: '24px'}}>Peças</h1>
                                    {/* <span style={{color: '#FFCD9D', fontSize: '16px'}}>Melhores ofertas</span> */}
                                </div>
                            </div>
        
                            <div className='homeSectionContainer center homeSectonsBorder UHDcatContainer' style={{backgroundImage: `url(${UHD_acessorios_bg_01})`, alignItems: 'start'}} onClick={() => navigateToCategory("Acessórios")}>
                                <div className='catText' style={{justifyContent: 'end'}}>
                                    <h1 style={{fontSize: '24px'}}>Acessórios</h1>
                                    {/* <span style={{color: '#FFCD9D', fontSize: '16px'}}>Melhores ofertas</span> */}
                                </div>
                            </div>
        
                        </div>
                    </div>
                    {/* <div className='homeSectionContainer'>
                        <div className='rowToColumnNoGap umuaramaHDofferContainer'>
        
                            <div style={{position: 'relative'}}>
                                <div className='largeOfferContainer'>
                                    <h1 style={{fontSize: '22px', backgroundColor: 'var(--mainColor)', color: 'white', padding: '2px 6px'}}>De R$ 4.819,00</h1>
                                    <h1 style={{fontSize: '28px', backgroundColor: 'var(--mainColor)', color: 'white', padding: '2px 6px'}}>Por R$ 4.096,00</h1>
                                </div>
                                <img src={UHD_offer_jacket_00} className='UhdOfferWide'/>
                            </div>

                            <div style={{position: 'relative'}}>
                                <div className='smallOfferContainer'>
                                    <h1 style={{fontSize: '22px', backgroundColor: 'var(--mainColor)', color: 'white', padding: '2px 6px'}}>De R$ 1.800,00</h1>
                                    <h1 style={{fontSize: '28px', backgroundColor: 'var(--mainColor)', color: 'white', padding: '2px 6px'}}>Por R$ 899,00</h1>
                                </div>
                                <img src={UHD_offer_helmet_00} className='UhdOfferNarrow'/>
                            </div>
        
                        </div>
                    </div> */}

                    {/* <div className='homeSectionContainer'>
                        <div className='rowToColumnNoGap' style={{maxWidth: '1280px', width: '100%', justifyContent: 'start'}}>
                            <img src={UHD_offer_jacket_01} className='UhdOfferWide'/>
                            <img src={UHD_offer_helmet_01} className='UhdOfferNarrow'/>
                        </div>
                    </div> */}
                </>
            }

            {configData.defaultStore !== 'UmuaramaHD' &&

                <>
                    <div className='homeSectionContainer'>
                        <div className='rowToColumnNoGap' style={{columnGap: '15px', maxWidth: '1280px', width: '100%', justifyContent: 'start'}}>

                            <div className='homeSectionContainer center homeSectonsBorder catContainer' style={{backgroundColor: '#222D37'}} onClick={() => categoryClick('Lubrificantes e Fluidos', LubrificantesFluidosImg)}>
                                <div className='row' style={{columnGap: '20px', alignItems: 'center'}}>
                                    <img src={oils_01} className='catImgHome'/>
                                    <div className='catText'>
                                        <h1>Lubrificantes Originais</h1>
                                        <span>Óleos e fluidos de fábrica para a melhor longevidade do seu veículo.</span>
                                    </div>
                                </div>
                            </div>

                            <div className='homeSectionContainer center homeSectonsBorder catContainer' style={{backgroundColor: '#1C252D'}} onClick={() => categoryClick('Filtros', FiltrosImg)}>
                                <div className='row' style={{columnGap: '20px', alignItems: 'center'}}>
                                    <img src={oilKit_01} className='catImgHome'/>
                                    <div className='catText'>
                                        <h1>Kits Completos de Reparo</h1>
                                        <span>Kits de troca de óleo, correia de acessórios e mais.</span>
                                    </div>
                                </div>
                            </div>

                            <div className='homeSectionContainer center homeSectonsBorder catContainer' style={{backgroundColor: '#171E25'}} onClick={() => categoryClick('Motor & Periféricos', MotorPerifericosImg)}>
                                <div className='row' style={{columnGap: '20px', alignItems: 'center'}}>
                                    <img src={engine_01} className='catImgHome'/>
                                    <div className='catText'>
                                        <h1>Motores Completos e Parciais</h1>
                                        <span>Filtre motores compatíveis com a versão seu veículo</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='homeSectionContainer center catHomePadding'>
                        {/* <h1>Mais Categorias:</h1> */}
                        <div className='centerGridHome'>
                            <div className='tileGridHome'>
                                {Object.entries(Categories).slice(0, 5).map(([category, image]) => (
                                    <CategoryTile
                                        key={category}
                                        name={category}
                                        image={image}
                                        onClick={() => { categoryClick(category, image) }}
                                        noMargin='0'
                                        imgSize='small'
                                    />
                                ))}
                                <CategoryTile
                                    key='all'
                                    name='Mais categorias'
                                    image={allCats}
                                    onClick={openVehicleSelect}
                                    noMargin='0'
                                    imgSize='small'
                                />
                            </div>
                        </div>
                    </div>

                    {/* <div className='homeSectionContainer'>
                        <div className='rowToColumnNoGap' style={{maxWidth: '1280px', width: '100%',
                            // backgroundColor: 'white'
                            }}>
                            <div className='homeSectionContainer center oilContainer homeSectonsBorder'>
                                <div className='rowToColumn800'>
                                    <img src={oilKit_01} className='oilImg'/>
                                    <div className='column' style={{color: 'white', alignItems: 'start', gap: '10px'}}>
                                        <h1>Kits Completos de Reparo</h1>
                                        <h2>óleos e filtros originais</h2>
                                        <span>Para dirigir tranquilo e seguro, opte por peças de freio originais. Com qualidade de fábrica garantida, curta suas viagens sem surpresas pelo caminho.</span>
                                        <Button
                                            label='Ver freios'
                                            className='buttonColorThird smallButton'
                                            onClick={() => {categoryClick('Freios', CategoriesPH['Freios'])}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='homeSectionContainer center engineContainer homeSectonsBorder'>
                                <div className='rowToColumn800'>
                                    <img src={engine_01} className='engineImg'/>
                                    <div className='column' style={{color: 'white', alignItems: 'start', gap: '10px'}}>
                                        <h1>Kits Completos de Reparo</h1>
                                        <h2>óleos e filtros originais</h2>
                                        <span>Para dirigir tranquilo e seguro, opte por peças de freio originais. Com qualidade de fábrica garantida, curta suas viagens sem surpresas pelo caminho.</span>
                                        <Button
                                            label='Ver freios'
                                            className='buttonColorThird smallButton'
                                            onClick={() => {categoryClick('Freios', CategoriesPH['Freios'])}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className='homeSectionContainer center' style={{padding: '0'}}>
                        <div className='homeSectonsBorder wideShort roadSection' style={{backgroundColor: 'var(--mainColor)', backgroundImage: `url(${roadBg_01})`, width: '100%'}}>
                            <div className='rowToColumn800 center' style={{padding: '20px', gap: '20px'}}>
                                <img src={brakesImg02} className='brakesImg01'/>
                                <div className='column' style={{color: 'white', alignItems: 'start', gap: '10px'}}>
                                    <h1>Tranquilidade pra curtir a estrada</h1>
                                    <h2>com autopeças originais de fábrica!</h2>
                                    <span>Para viajar tranquilo e seguro, opte por autopeças <mark>genuínas</mark>. Com qualidade de fábrica garantida, curta suas viagens sem surpresas pelo caminho.</span>
                                    <Button
                                        label='Freios originais'
                                        className='buttonColorThird smallButton'
                                        onClick={() => categoryClick('Freios', FreiosImg)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='homeSectionContainer' style={{padding: '0', gap: '10px', margin: '10px 0'}}>
                        <h1>Lojas Oficiais:</h1>
                        <div className='homeHighlightRow' style={{marginTop: '10px'}}>

                            <div className='storesHighlightItem' style={{color: 'white', backgroundColor: '#C9191A'}}>
                                <img src={BremboLogo}/>
                            </div>

                            <div className='storesHighlightItem' style={{color: 'white', backgroundColor: '#001E50'}}>
                                <img src={BrandsListSVG['Volkswagen']}/>
                            </div>

                            <div className='storesHighlightItem' style={{color: 'white', backgroundColor: '#AB1137'}}>
                                <img src={BrandsListSVG['Fiat']}/>
                            </div>

                            <div className='storesHighlightItem' style={{color: '#04329C', backgroundColor: 'white'}}>
                                <img src={AcdelcoLogo}/>
                            </div>

                            <div className='storesHighlightItem' style={{color: 'white', backgroundColor: 'black'}}>
                                <img src={BrandsListSVG['Renault']}/>
                            </div>

                        </div>
                    </div> */}

                    <div className='homeSectionContainer' style={{padding: '0'}}>
                        <div className='homeHighlightRow'>

                            <div className='homeHighlightItem'>
                                <DiamondOutlined style={{color: 'var(--secondColor)', fontSize: '32px'}}/>
                                <span>Somente peças novas e genuínas</span>
                                <span className='grayedText'>Entregue diretamente pelas concessionárias.</span>
                            </div>
                            <div className='homeHighlightItem'>
                                <NoCrashOutlined style={{color: 'var(--secondColor)', fontSize: '32px'}}/>
                                <span>Compatibilidades verificadas</span>
                                <span className='grayedText'>Selecione seu veículo e confira as peças compatíveis.</span>
                            </div>
                            <div className='homeHighlightItem'>
                                <VerifiedUserOutlined style={{color: 'var(--secondColor)', fontSize: '32px'}}/>
                                <span>Compra segura no PIX ou cartão</span>
                                <span className='grayedText'>Antecipe a sua entrega com pagamento no PIX.</span>
                                {/* <span className='grayedText'>Receba antes com aprovação imediata no PIX.</span> */}
                            </div>
                            <div className='homeHighlightItem'>
                                <LocalShippingOutlined style={{color: 'var(--secondColor)', fontSize: '32px'}}/>
                                <span>Entregas para todo o Brasil</span>
                                <span className='grayedText'>Encontre a concessionária mais próxima de você.</span>
                            </div>
                            <div className='homeHighlightItem'>
                                <LocalMallOutlined style={{color: 'var(--secondColor)', fontSize: '32px'}}/>
                                <span>Retire no balcão</span>
                                <span className='grayedText'>Pague mais barato e retire na concessionária.</span>
                            </div>
                        </div>
                    </div>

                    <div className='homeSectionContainer center' style={{padding: '0'}}>
                        <div className='column center brandsContainer homeSectonsBorder' style={{backgroundImage: `url(${GPlogoPatternBG_01})`, width: '100%'}}>
                            <h1 style={{padding: '20px'}}>Autopeças novas e genuínas das maiores montadoras e fabricantes homologados:</h1>
                            <div className='row' style={{gap: '6px', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '800px', paddingInline: '5px'}}>
                                {Object.entries(BrandsListSVG).map(([brandName, brandLogo]) => (
                                    <img style={{width: '64px'}} key={brandName} src={brandLogo} alt={brandName} />
                                ))}
                            </div>
                            <div className='row' style={{columnGap: '20px', flexWrap: 'wrap', justifyContent: 'center', borderTop: '1px solid rgba(255, 255, 255, 0.3)', padding: '20px 0', marginTop: '20px'}}>
                                {Object.entries(HomologBrandsSVG).map(([brandName, brandLogo]) => (
                                    <img style={{width: '100px'}} key={brandName} src={brandLogo} alt={brandName} />
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* <div className='homeSectionContainer' style={{padding: '0'}}>
                        <div className='center whatsappSectionContainer homeSectonsBorder' style={{backgroundImage: `url(${Whataspp_bg})`}}>
                            <div className='column' style={{padding: '20px', gap: '10px'}}>
                                <h1>"Qual é a peça certa para o meu carro?"</h1>
                                <span>Ficou na dúvida? Chame nossos atendentes especializados em autopeças e garanta a peça correta para seu veículo.</span>
                                <div className='whatsappCtaContainer'>
                                    <div className='row center'>
                                        <img src={GoParca_Show_01} className='GoParcaShow'/>
                                        <span className='mobileSpan'>{whatsappInfo}</span>
                                    </div>
                                    <div className='column' style={{gap: '6px', margin: '10px 0'}}>
                                        <Button
                                            className='buttonStyle buttonColorThird smallButton'
                                            label='Fale com um especialista'
                                            iconStart={<WhatsappIcon/>}
                                        />
                                        <span className='deskSpan'>{whatsappInfo}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img className='GoParcaSearch' src={GoParca_Search_01}/>
                            </div>
                        </div>
                    </div> */}

                    <div className='homeSectionContainer'>
                        <div className='rowToColumnGap' style={{maxWidth: '1280px', width: '100%', marginTop: '20px'}}>

                            <div className='reviewContainer reclameMark' style={{backgroundColor: 'white'}}>
                                <div className='row' style={{alignItems: 'center', gap: '10px'}}>
                                    <ReclameAquiSeal/>
                                    <div className='column'>
                                        <div className='row' style={{alignItems: 'center', gap: '10px'}}>
                                            <img src={ReclameOtimo} className='reclameImg'/>
                                            <img src={ReclameLogo} className='reclameLogo'/>
                                        </div>
                                        <h1>Nota <mark>8.7/10</mark></h1>
                                        <h1>Reputação <mark>ÓTIMA</mark></h1>
                                    </div>
                                </div>
                            </div>
                            {/* <a className='reviewContainer reclameMark' style={{backgroundColor: 'white'}} href='https://www.reclameaqui.com.br/empresa/goparts-intermediacao-de-negocios/' target='blank'>
                                <div className='row' style={{alignItems: 'center', gap: '10px'}}>
                                    <ReclameAquiSeal/>
                                    <div className='column'>
                                        <div className='row' style={{alignItems: 'center', gap: '10px'}}>
                                            <img src={ReclameOtimo} className='reclameImg'/>
                                            <img src={ReclameLogo} className='reclameLogo'/>
                                        </div>
                                        <h1>Nota <mark>8.7/10</mark></h1>
                                        <h1>Reputação <mark>ÓTIMA</mark></h1>
                                    </div>
                                </div>
                            </a> */}

                            <a className='reviewContainer googleMark' style={{backgroundImage: `url(${GoogleMaps_bg})`}} href='https://maps.app.goo.gl/7ZwwWj7WjSfBdAuZA' target='blank'>
                                <div className='column'>
                                    <div className='row' style={{alignItems: 'center', justifyContent: 'center', gap: '10px'}}>
                                        <img src={GoogleWhite} className='googleLogo'/>
                                        <h1 style={{transform: 'translateY(-6px)'}}>Avaliações</h1>
                                    </div>
                                    <h1> Nota <mark>5,0 <span style={{display: 'inline-block', transform: 'translateY(4px)'}}>
                                                <Star className='googleStar'/>
                                                <Star className='googleStar'/>
                                                <Star className='googleStar'/>
                                                <Star className='googleStar'/>
                                                <Star className='googleStar'/>
                                            </span>
                                        </mark>
                                    </h1>
                                </div>
                            </a>

                            <div className='reviewContainer whatsappMark' style={{backgroundImage: `url(${Whataspp_bg})`}}>
                                <div className='GoParcaShowContainer'>
                                    <img src={GoParca_Show_01} className='GoParcaShowDesktop'/>
                                    <img src={GoParca_Show_02} className='GoParcaShowMobile'/>
                                    {/* <img src={GoParca_Show_01} className='GoParcaShow'/> */}
                                </div>
                                <div className='column' style={{gap: '8px', margin: '10px 0'}}>
                                    <h1>Ficou na dúvida?</h1>
                                    <Button
                                        className='buttonStyle buttonColorThird smallButton'
                                        label='Fale com um especialista'
                                        iconStart={<WhatsappIcon/>}
                                        onClick={() => handleWhatsappClick(selectedBrand, selectedModel, selectedYear, selectedVersion)}
                                    />
                                    <span>{whatsappInfo}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='homeSectionContainer'>
                        <div className='rowToColumnNoGap' style={{maxWidth: '1280px', width: '100%',}}>
                            <div className='center reclameContainer homeSectonsBorder'>
                                <div className='row' style={{alignItems: 'center', gap: '10px'}}>
                                    <img src={ReclameOtimo} className='reclameImg'/>
                                    <div className='column'>
                                        <img src={ReclameLogo} className='reclameLogo'/>
                                        <h1>Nota <mark>8.3/10</mark></h1>
                                        <h1>Reputação <mark>ÓTIMA</mark></h1>
                                        <span style={{marginTop: '10px'}}>Nossa prioridade é cuidar do nosso cliente, proporcionando uma experiência de compra tranquila e sem surpresas.</span>
                                        <span style={{marginTop: '6px'}}>Ver mais no <a href='https://www.reclameaqui.com.br/empresa/goparts-intermediacao-de-negocios/' target='blank'>reclameaqui.com.br</a></span>
                                    </div>
                                </div>
                            </div>

                            <div className='center googleContainer homeSectonsBorder' style={{backgroundImage: `url(${GoogleMaps_bg})`}}>
                                <div className='column'>
                                    <div className='row' style={{alignItems: 'center', gap: '10px'}}>
                                        <img src={GoogleWhite} className='googleLogo'/>
                                        <h1 style={{transform: 'translateY(-6px)'}}>Avaliações</h1>
                                    </div>
                                    <h1> Nota média: <mark>5,0
                                            <Star style={{fontSize: '28px', color: 'orange'}}/>
                                            <Star style={{fontSize: '28px', color: 'orange'}}/>
                                            <Star style={{fontSize: '28px', color: 'orange'}}/>
                                            <Star style={{fontSize: '28px', color: 'orange'}}/>
                                            <Star style={{fontSize: '28px', color: 'orange'}}/>
                                        </mark>
                                    </h1>
                                    <span style={{marginTop: '10px'}}>Veja o que nossos clientes falam da GoParts nas avaliações do Google.</span>
                                    <span style={{marginTop: '6px'}}>Ver mais em <a href='https://maps.app.goo.gl/7ZwwWj7WjSfBdAuZA' target='blank'>Google Avaliações</a></span>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className='homeSectionContainer' style={{padding: '0'}}>
                        <div className='rowToColumn800 homeSectonsBorder paymentContainer' style={{backgroundColor: 'var(--veryLightGray) !important'}}>
                            <div className='homeSectionContainer center payRowLeft'>
                                <img src={GoParca_Zen} className='paymentImg'/>
                            </div>
                            <div className='homeSectionContainer center payRowRight'>
                                <h1>Compre com Segurança e Tranquilidade</h1>
                                <div className='row' style={{gap: '10px', alignItems: 'center'}}>
                                    <div className='column' style={{gap: '10px', alignItems: 'start'}}>
                                        <span>Compre peças originais para o seu veículo sem preocupações, em um ambiente digital seguro contra falsificações e má procedência.</span>
                                        <span>Confira nossa <a href='/privacidade'>Política de Privacidade</a></span>
                                    </div>
                                    <img src={GoParca_Zen_2} className='paymentImgMobile'/>
                                </div>
                                <div className='row' style={{flexWrap: 'wrap', gap: '20px'}}>
                                    {CardOperators.map(item => (
                                        <img src={item} className='cardOpImg'/>
                                    ))}
                                </div>
                                <div className='row' style={{flexWrap: 'wrap', gap: '20px', alignItems: 'center', borderTop: '1px solid rgba(0, 0, 0, 0.12)', paddingTop: '20px'}}>
                                    <div className='antifraudContainer' style={{backgroundColor: 'var(--lightGray)'}}>
                                        <img src={antifraudIcon}/>
                                        <div className='column center' style={{gap: '2px'}}>
                                            <h3>PAGAMENTO<br/>SEGURO</h3>
                                            {/* <span>com sistema<br/>antifraude</span> */}
                                        </div>
                                    </div>
                                    <img src={Lgpd} className='payImg'/>
                                    <img src={Ssl} className='payImg'/>
                                    {/* <img src={Pix} className='payImg'/> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='homeSectionContainer' style={{padding: '0'}}>
                        <div className='rowToColumn homeSectonsBorder aboutUsHomeContainer' style={{maxWidth: '1280px', justifyContent: 'center'
                            // backgroundImage: `url(${AboutUsBg})`
                        }}>
                            <div className='column center' style={{ color: 'white', padding: '20px'}}>
                                <div className='row'>
                                    <div className='column aboutUsContainer'>
                                        <h2>{title}</h2>
                                        {content.map((text, index) => (
                                            <span key={index}>{text}</span>
                                        ))}
                                        <div className='aboutUsMiddleMarginRow'>
                                            <Button
                                                className='buttonColorSecond smallButton'
                                                label={storeInfo.aboutUs.linkCta}
                                                onClick={handleAboutUsClick}
                                            />
                                            <Button
                                                className='buttonColorThird smallButton'
                                                label='Comprar peças'
                                                onClick={openVehicleSelect}
                                            />
                                        </div>
                                    </div>
                                    <div className='aboutUsEmpty'/>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </>
            }


        </div>
    );
}

export default Home;