import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Common/Button';
import { Close, Edit } from '@mui/icons-material';

const AddressTile = ({ name, address, isSelected, onClick, isDefault, canEdit, editClick, deleteClick, highlight }) => {
  return (
    <>
      {!canEdit
      ?
        <div
          className={`row clickArea defaultBorderedItem ${isSelected ? 'selectedItem' : 'itemSelect'} ${highlight ? 'highlight' : ''}`}
          onClick={onClick}
          style={{ gap: '10px', justifyContent: 'space-between', alignItems: 'center', paddingTop: 'var(--mediumPadding)', paddingBottom: 'var(--mediumPadding)'}}
        >
          <div className='column' style={{gap: 'var(--smallRowGap)'}}>
            <span>{name}</span>
            <span>{address}</span>
          </div>
          <div className='column' style={{ alignItems: 'end', color: 'var(--mainColor)', gap: '4px', whiteSpace: 'nowrap' }}>
            {/* Any additional content */}
          </div>
          {isDefault &&
            <span style={{color: 'var(--mainColor)'}}>Padrão</span>
          }
        </div>
      :
        <div
          className={`row defaultBorderedItem ${highlight ? 'highlight' : ''}`}
          style={{ gap: '10px', justifyContent: 'space-between', alignItems: 'center', paddingTop: 'var(--mediumPadding)', paddingBottom: 'var(--mediumPadding)'}}
        >
          <div className='column' style={{gap: 'var(--smallRowGap)'}}>
            <span>{name}</span>
            <span>{address}</span>
          </div>
          <div className='column' style={{ alignItems: 'end', color: 'var(--mainColor)', gap: '4px', whiteSpace: 'nowrap' }}>
            {/* Any additional content */}
          </div>
          {canEdit &&
            <div className='row' style={{gap: '20px'}}>
              <a onClick={editClick}>Editar</a>
              <a onClick={deleteClick}>Excluir</a>
            </div>
          }
        </div>
      }
    </>
  );
};

AddressTile.propTypes = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default AddressTile;