import React, { useState, useEffect } from 'react';
import '../App.css';
import Button from '../Common/Button';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import PasswordToggle from '../Common/PasswordToggle';
import { CircularProgress } from '@mui/material';
import GoogleIcon from '../images/google_g_logo.png';
import { useUser } from '../Providers/UserContext.js'

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const destination = queryParams.get('destination');
  const [isLoading, setIsLoading] = useState(false);
  const [visibility, setVisibility] = useState({
    loginPassword: false,
  });

  const user = useUser(); // Access the user from context

  const toggleVisibility = (field) => {
    setVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleSenha = (event) => {
    setSenha(event.target.value);
  };

  const login = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, senha);
      setIsLoading(false);
      if (destination) {
        navigate(destination);
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user.gpuser) {
      navigate('/');
    }
  }, [user]);

  return (
    <div className="main fadeIn">
      {isLoading ? (
        <div className="pageContent center column">
          <CircularProgress
            style={{ padding: '20px' }}
            sx={{
              color: 'var(--mainColor)',
            }}
          />
        </div>
      ) : (
        <div className="pageContent column" style={{ alignItems: 'center', gap: '10px' }}>
          <div className="card">
            <div className="formContainer">
              <form className="column" style={{ padding: '20px', gap: '10px' }} onSubmit={login}>
                <h2>Ainda não sou cliente</h2>
                <Button
                  className="buttonStyle buttonColorThird largeButton"
                  label="Criar conta"
                  navLink={destination ? `/signup?destination=${destination}` : '/signup'}
                />
                {/* <div style={{ borderTop: '1px solid var(--veryLightGray)', marginTop: '10px' }} /> */}
                <h2 style={{ marginTop: '20px' }}>Já sou cliente</h2>
                <input
                  type="text"
                  placeholder="email"
                  value={email}
                  onChange={handleEmail}
                />
                <div className="formFieldRow">
                  <input
                    type={visibility.loginPassword ? 'text' : 'password'}
                    placeholder="senha"
                    value={senha}
                    onChange={handleSenha}
                  />
                  <PasswordToggle
                    isVisible={visibility.loginPassword}
                    onClick={() => toggleVisibility('loginPassword')}
                  />
                </div>
                <Button
                  className="buttonStyle buttonColorMain largeButton"
                  label="Entrar"
                  type="submit"
                />
                <div className="column center">
                  <NavLink to={'/recuperar-senha'}>Esqueci a senha</NavLink>
                </div>


              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
