import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import Button from '../Button';
import '../../App.css';
import '../../Product/ProductPage.css';

import CloseIcon from "@mui/icons-material/Close";


// import required modules
import { FreeMode, Navigation, Thumbs, Pagination, Zoom} from 'swiper/modules';
import { Close } from '@mui/icons-material';

export default function ProductImgSwiper(props) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [zoomVisible, setZoomVisible] = useState(false); // State to control visibility

    // Handler to toggle zoom view
    const toggleZoom = () => {
        setZoomVisible(!zoomVisible);
    };
    return (
        <>
            <Swiper
                loop={true}
                spaceBetween={10}
                modules={[FreeMode, Navigation, Pagination, Thumbs]}
                navigation={true}
                pagination={{ clickable: true }}
                thumbs={{ swiper: thumbsSwiper }}
                className="productPageSwiper"
            >
                {props.images.map((image, index) => (
                    <SwiperSlide key={index}> {/* Add a unique key here */}
                        <img src={image} alt={image} className='productPageImg' onClick={toggleZoom} loading='lazy'/>
                    </SwiperSlide>
                ))}
            </Swiper>
            {props.images.length > 1 && (
                <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={50}
                    slidesPerView={5}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="productPageSwiperThumbs"
                >
                    {props.images.map((image, index) => (
                        <SwiperSlide key={index}> {/* Add a unique key here */}
                            <img src={image} alt={image} className='productPageImgThumb' loading='lazy'/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
            {/* Zoom Swiper */}
            <Swiper
                loop={true}
                spaceBetween={0}
                modules={[FreeMode, Navigation, Pagination, Thumbs]}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                className={`productPageSwiperZoom ${zoomVisible && 'openZoom'} fadeIn`}
                onClick={toggleZoom}
            >
                {props.images.map((image, index) => (
                    <SwiperSlide key={index}> {/* Add a unique key here */}
                        <div className='center' style={{height: '100%'}}>
                            <img src={image} alt={image} className='productPageImgZoom' loading='lazy'/>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}