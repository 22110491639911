import * as API from '../api';

// Action Types
export const ADD_VEHICLE = 'ADD_VEHICLE';
export const SET_IS_SELECTING_VEHICLE = 'SET_IS_SELECTING_VEHICLE';
export const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND';
export const SET_SELECTED_YEAR = 'SET_SELECTED_YEAR';
export const SET_SELECTED_MODEL = 'SET_SELECTED_MODEL';
export const SET_MODEL_SERIES = 'SET_MODEL_SERIES';
export const SET_SELECTED_VERSION = 'SET_SELECTED_VERSION';
export const SET_CAR_ID = 'SET_CAR_ID';
export const SET_INTERNAL_ID = 'SET_INTERNAL_ID';
export const LOAD_ALL_CARS = 'LOAD_ALL_CARS';
export const ERASE_SELECTED_VEHICLE = 'ERASE_SELECTED_VEHICLE';
export const LOAD_OE_BY_CARCODE_TYPE = 'LOAD_OE_BY_CARCODE_TYPE';
export const GET_PARTS_LIST_FROM_WH = 'GET_PARTS_LIST_FROM_WH';
export const GET_CATEGORY_WITH_ID_PROD = 'GET_CATEGORY_WITH_ID_PROD';

// Action Creators
export const addVehicle = (vehicle) => {
    localStorage.setItem('userVehicles', JSON.stringify([...JSON.parse(localStorage.getItem('userVehicles') || '[]'), vehicle]));
    return {
        type: ADD_VEHICLE,
        payload: vehicle,
    };
};

export const setIsSelectingVehicle = (isSelecting) => ({
    type: SET_IS_SELECTING_VEHICLE,
    payload: isSelecting,
});

export const setSelectedBrand = (brand) => {
    localStorage.setItem('selectedBrand', brand);
    return {
        type: SET_SELECTED_BRAND,
        payload: brand,
    };
};

export const setSelectedYear = (year) => {
    localStorage.setItem('selectedYear', year);
    return {
        type: SET_SELECTED_YEAR,
        payload: year,
    };
};

export const setSelectedModel = (model) => {
    localStorage.setItem('selectedModel', model);
    return {
        type: SET_SELECTED_MODEL,
        payload: model,
    };
};

export const setModelSeries = (modelSeries) => {
    localStorage.setItem('modelSeries', modelSeries);
    return {
        type: SET_MODEL_SERIES,
        payload: modelSeries,
    };
};

export const setSelectedVersion = (version) => {
    localStorage.setItem('selectedVersion', version);
    return {
        type: SET_SELECTED_VERSION,
        payload: version,
    };
};

export const setCarID = (carID) => {
    localStorage.setItem('carID', carID);
    return {
        type: SET_CAR_ID,
        payload: carID,
    };
};

export const setInternalID = (InternalID) => {
    localStorage.setItem('InternalID', InternalID);
    return {
        type: SET_INTERNAL_ID,
        payload: InternalID,
    };
};

export const eraseSelectedVehicle = () => {
    // console.log('eraseSelectedVehicle triggered!');
    // Clear specified items from localStorage
    localStorage.removeItem('selectedBrand');
    localStorage.removeItem('selectedYear');
    localStorage.removeItem('selectedModel');
    localStorage.removeItem('modelSeries');
    localStorage.removeItem('selectedVersion');
    localStorage.removeItem('carID');
    localStorage.removeItem('InternalID');
    localStorage.removeItem('carOEs');
    localStorage.removeItem('partsFromWH');
    localStorage.removeItem('categoryWithIdProd');

    return {
        type: ERASE_SELECTED_VEHICLE,
        payload: {},
    };
};

// Async Actions
export const loadAllCars = () => async (dispatch) => {
    try {
        const result = await API.loadAllCars();
        // Save the result to localStorage
        localStorage.setItem('allCars', JSON.stringify(result));
        dispatch({
            type: LOAD_ALL_CARS,
            payload: result,
        });
    } catch (error) {
        console.error('Erro ao carregar carros:', error);
    }
};

export const loadOEbyCarCodeType = (InternalID) => async (dispatch) => {
    try {
        const result = await API.loadOEbyCarCodeType(InternalID);
        // Save the result to localStorage
        localStorage.setItem('carOEs', JSON.stringify(result));
        dispatch({
            type: LOAD_OE_BY_CARCODE_TYPE,
            payload: result,
        });
        // console.log('parts result: ', result);
        return result;
    } catch (error) {
        console.error('Erro ao carregar OEs:', error);
        // Set carOEs as empty in case of error
        localStorage.setItem('carOEs', JSON.stringify([])); // Clear localStorage
        dispatch({
            type: LOAD_OE_BY_CARCODE_TYPE,
            payload: [], // Dispatch empty array to Redux state
        });
    }
};

export const getPartsListFromWH = (info) => async (dispatch) => {
    try {
        const result = await API.getPartsListFromWH(info);
        // Save the result to localStorage
        localStorage.setItem('partsFromWH', JSON.stringify(result));
        dispatch({
            type: GET_PARTS_LIST_FROM_WH,
            payload: result,
        });
    } catch (error) {
        console.error('Erro ao carregar peças da warehouse:', error);
    }
};

export const getCategoryWithIdProd = (id_prod) => async (dispatch) => {
    try {
        const result = await API.getCategoryWithIdProd(id_prod);
        // Save the result to localStorage
        localStorage.setItem('categoryWithIdProd', JSON.stringify(result));
        dispatch({
            type: GET_CATEGORY_WITH_ID_PROD,
            payload: result,
        });
        return result;
    } catch (error) {
        console.error('Erro ao obter a categoria pelo id_prod:', error);
    }
};

// Initial State
const initialState = {
    userVehicles: JSON.parse(localStorage.getItem('userVehicles') || '[]'), 
    isSelectingVehicle: false,
    selectedBrand: localStorage.getItem('selectedBrand') === 'undefined' ? undefined : localStorage.getItem('selectedBrand'),
    selectedYear: localStorage.getItem('selectedYear') === 'undefined' ? undefined : localStorage.getItem('selectedYear'),
    selectedModel: localStorage.getItem('selectedModel') === 'undefined' ? undefined : localStorage.getItem('selectedModel'),
    modelSeries: localStorage.getItem('modelSeries') === 'undefined' ? undefined : localStorage.getItem('modelSeries'),
    selectedVersion: localStorage.getItem('selectedVersion') === 'undefined' ? undefined : localStorage.getItem('selectedVersion'),
    selectedVehicle: JSON.parse(localStorage.getItem('selectedVehicle') || '{}'), 
    allCars: JSON.parse(localStorage.getItem('allCars') || '[]'), 
    carOEs: JSON.parse(localStorage.getItem('carOEs') || '[]'), 
    partsFromWH: JSON.parse(localStorage.getItem('partsFromWH') || '[]'), 
    categoryWithIdProd: JSON.parse(localStorage.getItem('categoryWithIdProd') || '[]'), 
    carID: localStorage.getItem('carID') || null,  // Load carID from localStorage
    InternalID: localStorage.getItem('InternalID') || null  // Load InternalID from localStorage
};

// Reducer
const vehicle = (state = initialState, action) => {
    switch (action.type) {
        case ADD_VEHICLE:
            const newVehicle = action.payload;
            // Filter out duplicates
            const updatedVehicle = state.userVehicles.filter(
                vehicle => vehicle.brand !== newVehicle.brand ||
                           vehicle.year !== newVehicle.year ||
                           vehicle.model !== newVehicle.model ||
                           vehicle.version !== newVehicle.version
            );
            // Add the new vehicle at the beginning
            updatedVehicle.unshift(newVehicle);
            return { ...state, userVehicles: updatedVehicle, selectedVehicle: newVehicle };
        case SET_IS_SELECTING_VEHICLE:
            return { ...state, isSelectingVehicle: action.payload };
        case SET_SELECTED_BRAND:
            return { ...state, selectedBrand: action.payload };
        case SET_SELECTED_YEAR:
            return { ...state, selectedYear: action.payload };
        case SET_SELECTED_MODEL:
            return { ...state, selectedModel: action.payload };
        case SET_MODEL_SERIES:
            return { ...state, modelSeries: action.payload };
        case SET_SELECTED_VERSION:
            return { ...state, selectedVersion: action.payload };
        case SET_CAR_ID:
            return { ...state, carID: action.payload };
        case SET_INTERNAL_ID:
            return { ...state, InternalID: action.payload };
        case LOAD_ALL_CARS:
            return { ...state, allCars: action.payload };
        case ERASE_SELECTED_VEHICLE:
            return { ...state, selectedVehicle: action.payload };
        case LOAD_OE_BY_CARCODE_TYPE:
            return { ...state, carOEs: action.payload };
        case GET_PARTS_LIST_FROM_WH:
            return { ...state, partsFromWH: action.payload };
        case GET_CATEGORY_WITH_ID_PROD:
            return { ...state, categoryWithIdProd: action.payload };
        default:
            return state;
    }
};

export default vehicle;
