import React, { useState, useEffect } from 'react';
import '../App.css';
import Button from '../Common/Button';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { CheckCircleOutline } from '@mui/icons-material';
import { functions } from '../firebase';
import { configData } from '../Whitelabel/websitesData';
import { httpsCallable } from 'firebase/functions';
import { auth } from '../firebase';
import { useUser } from '../Providers/UserContext';



function ResetPwd() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const {gpuser,setUser} = useUser();
  const [resetSent, setResetSent] = useState(false);

  useEffect(() => {
    if (gpuser || gpuser != null) {
      setEmail(gpuser.email);
    }
  }, [gpuser]);

  const handleEmail = (event) => {
    setEmail(event.target.value);
  }

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const resetClick = () => {
    if (email !== '' && isValidEmail(email)) {
      resetPwd(email);
      setResetSent(true);
    } else {
      alert('Insira um email de uma conta existente');
    }
  }

  const backToLogin = () => {
    navigate('/login');
  }

  const resetPwd = async (email) => {
    const sendResetEmail = httpsCallable(functions, 'sendResetEmail');

    try {
        const response = await sendResetEmail({
            email: email,
            loja: configData.defaultStore,
            linkloja: configData.defaultStoreLink,
            emailloja: configData.defaultContactEmail
        });
        // console.log(response.data); // Handle the response as needed
    } catch (error) {
        console.error("Erro ao enviar email de recuperação de senha: ", error);
        // Handle error (e.g., show a notification to the user)
    }
  }

  return (
    <div className="main">
        <div className='pageContent column' style={{alignItems: 'center', gap: '10px'}}>
          <div className='card'>
            <div className='formContainer'>
              {!resetSent
                ?
                  <div className='column fadeIn' style={{padding: '20px', gap: '10px'}}>
                    {gpuser ?
                      <h2>Alterar Senha</h2>
                    :
                      <h2>Recuperar Senha</h2>
                    }
                    <input 
                      type='email' 
                      placeholder='email'
                      value={email}
                      onChange={handleEmail}
                      disabled={gpuser !== null}
                    />
                    <Button
                      className='buttonStyle buttonColorMain largeButton'
                      label='Enviar email de recuperação'
                      onClick={resetClick}
                    />
                    <div className='column center'>
                      <NavLink to={'/login'}>
                        Voltar
                      </NavLink>
                    </div>
                  </div>
                :
                  <div className='column fadeIn' style={{padding: '20px', gap: '10px'}}>
                    <h2>Recuperar Senha</h2>
                    <div className='column  center'>
                      <CheckCircleOutline style={{fontSize: '64px', color: 'var(--highlightGreen)'}}/>
                    </div>
                    <span>Um link de recuperação de senha foi enviado para o seu email :)</span>
                    <Button
                      className='buttonStyle buttonColorMain largeButton'
                      label='Ok'
                      onClick={backToLogin}
                    />
                  </div>
              }
            </div>
          </div>
        </div>
    </div>
  );
}

export default ResetPwd;