import React, { useEffect } from 'react';

const ReclameAquiSeal = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'ra-embed-verified-seal';
        script.src = 'https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js';
        script.setAttribute('data-id', 'd0JHamhqT2xUTkhva042Uzpnb3BhcnRzLWludGVybWVkaWFjYW8tZGUtbmVnb2Npb3M=');
        script.setAttribute('data-target', 'ra-verified-seal');
        script.setAttribute('data-model', '1');

        document.getElementById('ra-verified-seal').appendChild(script);

        // Cleanup function to remove the script when the component unmounts
        return () => {
            const existingScript = document.getElementById('ra-embed-verified-seal');
            if (existingScript) {
                existingScript.remove();
            }
        };
    }, []);

    return <div id="ra-verified-seal"></div>;
};

export default ReclameAquiSeal;