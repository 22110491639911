import { firestore } from '../firebase'; // Adjust the import according to your Firebase configuration
import { query, where, getDocs, collection } from "firebase/firestore";

export const getMktName = async (name, setMktName) => {
  const warehouseRef = collection(firestore, 'dealers'); // Reference to the collection
  const q = query(warehouseRef, where('nome', '==', name));

  const querySnapshot = await getDocs(q); // Get the query snapshot
  if (querySnapshot.docs.length > 0) {
    const item = querySnapshot.docs[0].data();
    setMktName(item.nomeMKT); // Update the state with the fetched market name
  } else {
    setMktName(null); // Handle case where no document is found
  }
};