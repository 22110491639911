import React, { useEffect, useRef, useState } from 'react';
import '../../App.css';
import '../../Home/Home.css';
import './BannerSlider.css';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { setIsSelectingVehicle, setSelectedBrand, setSelectedYear, setSelectedModel, setSelectedVersion } from '../../reducers/vehicle';
import { useDispatch, useSelector } from 'react-redux';
import 'swiper/css';
import 'swiper/css/bundle';
import banner01_img from '../../images/mainBG_01.webp';
import SearchIcon from "@mui/icons-material/Search";
import FilterIcon from "@mui/icons-material/FilterList";
import Logo from '../../images/GoParts_Logo_24_principal_light.png';
import Button from '../Button';
import bannerVideo01 from '../../images/GoParts_24_BG_01_4mpbs.mp4';
import bannerVideo01Fallback from '../../images/banner_vid_preview_01.jpg';
import roadBg_01 from '../../images/home_road_bg_01.jpg';
import roadBg_02 from '../../images/home_road_bg_02.jpg';
import humanBg_01 from '../../images/home_humans_bg_01.jpg';
import humanBg_02 from '../../images/home_humans_bg_02.jpg';
import partsBg_01 from '../../images/home_parts_bg_01.jpg';
import magentaBg_01 from '../../images/home_magentaBg_01.jpg';
import goparts_logo_01 from '../../images/about_GoParts_bg_03.jpg';
import GenericVehicle from '../../images/0.png';

import { ChevronRight, KeyboardDoubleArrowUp, Search, Edit } from '@mui/icons-material';
import { AddCar } from '../../Utilities/Icons';
import { AddShoppingCart } from '@mui/icons-material';

import carBanner from  '../../images/car_homeBanner_01.webp';
import partsBanner from  '../../images/steps_part_01.webp';
import boxGpBanner from  '../../images/steps_GP_box_01.webp';

import GPlogoPatternBG_01 from '../../images/GoParts_Logos_Pattern_01.jpg';
import { BrandsListSVG, HomologBrandsSVG } from '../../Utilities/BrandsList';
import { storeInfo } from '../../Utilities/Placeholders';
import { BrandsList } from '../../Utilities/BrandsList';
import { ModelImgs } from '../../Utilities/ModelImgs';

import { contentData, configData } from '../../Whitelabel/websitesData';


function BannerSlider() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { title, content, link } = storeInfo.aboutUs;

    const handleAboutUsClick = () => {
        window.open(`${link}`, '_blank');
    }

    const { selectedBrand, isSelectingVehicle, selectedYear, selectedModel, selectedVersion, modelSeries } = useSelector(state => state.vehicle);

    const modelSeriesImg = (modelId) => {
        return ModelImgs[modelId] || GenericVehicle;
    };

    const openVehicleSelect = () => {
        //console.log('version: ', selectedVersion);
        if (selectedVersion) {
            navigate('/categorias');
        } else {
            dispatch(setIsSelectingVehicle(!isSelectingVehicle));
        }
    };

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
      progressCircle.current.style.setProperty('--progress', 1 - progress);
      progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };

    const vehicleSelectWithBrand = (brand) => {
        dispatch(setSelectedVersion(''));
        dispatch(setSelectedModel(''));
        dispatch(setSelectedYear(''));
        dispatch(setSelectedBrand(brand));
        dispatch(setIsSelectingVehicle(true));
    }

    const openVehicleSelectOnly = () => {
        dispatch(setIsSelectingVehicle(true));
    }

    return (
        <div>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, Autoplay ]}
                loop={true}
                spaceBetween={0}
                slidesPerView={1}
                navigation={true}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoplay={{
                    delay: 15000,
                    disableOnInteraction: false,
                }}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
            >

                <div className="autoplay-progress" slot="container-end">
                    <svg viewBox="0 0 48 48" ref={progressCircle}>
                        <circle cx="24" cy="24" r="20"></circle>
                    </svg>
                    <span ref={progressContent}></span>
                </div>

                {configData.defaultStore === 'GoParts' 
                    ?
                        <>
                            <SwiperSlide>
                                <div className='defaultBannerBG' style={{backgroundImage: `url(${bannerVideo01Fallback})`}}>
                                {/* <div className='bannerContainer'>
                                    <video autoPlay muted loop className='bannerVideo' poster={bannerVideo01Fallback}>
                                        <source src={bannerVideo01} type="video/mp4" />
                                    </video> */}

                                    {!selectedVersion &&
                                        <div className='titleContainer not500' style={{flexDirection: 'column'}}>
                                            <h1><mark>1.</mark> Selecione seu veículo</h1>
                                            <h1><mark>2.</mark> Encontre a peça desejada</h1>
                                            <h1><mark>3.</mark> Compre e receba</h1>
                                        </div>
                                    }

                                    {!selectedVersion &&
                                        <div className='only500'>
                                            <h1>Selecione seu veículo:</h1>
                                        </div>
                                    }
                                    
                                    {selectedVersion &&
                                        <h1>Veículo selecionado:</h1>
                                    }
                                    
                                    {!selectedVersion
                                        ?
                                            <div className='column center' style={{gap: '10px'}}>
                                                <div className='bannerRowBrands'>
                                                    {configData.brands.map((item) => {
                                                        const brandName = item.charAt(0).toUpperCase() + item.slice(1);
                                                        const imageSrc = BrandsList[brandName];
                                                        return imageSrc ? (
                                                        <div
                                                            className='headerFilterOption'
                                                            key={item}
                                                            onClick={() => vehicleSelectWithBrand(item)}
                                                        >
                                                            <img className='headerFilterBrandLogo' src={imageSrc} alt={item} />
                                                        </div>
                                                        ) : (
                                                        <div className='headerFilterOption' style={{ color: 'white' }} key={item}>
                                                            {item}
                                                        </div>
                                                        );
                                                    })}
                                                </div>
                                                <div className='column center' style={{gap: '6px'}}>
                                                    <span>ou</span>
                                                    <Button
                                                        onClick={openVehicleSelectOnly}
                                                        className="buttonColorThird smallButton"
                                                        label="Encontre pela placa"
                                                        iconStart={<Search/>}
                                                    />
                                                </div>
                                            </div>
                                        :
                                            <div className='column center' style={{gap: '20px'}}>
                                                <div className='row selectedVehicleHomeBanner' onClick={openVehicleSelectOnly}>
                                                    <img className='catVehicleImg' src={modelSeriesImg(modelSeries)}/>
                                                    <span style={{alignItems: 'center', whiteSpace: 'wrap'}} >{selectedModel} {selectedYear} {selectedVersion}</span>
                                                    <a style={{whiteSpace: 'nowrap'}}><Edit style={{fontSize: '16px', marginLeft: '6px'}}/> alterar</a>
                                                </div>
                                                <Button
                                                    className='giantButton'
                                                    iconStart={<span><AddShoppingCart style={{color: 'white', position: 'relative', zIndex: 1}}/></span>}
                                                    label='Peças compatíveis'
                                                    onClick={openVehicleSelect}
                                                />
                                            </div>
                                    }
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='defaultBannerBG' style={{backgroundColor: 'var(--secondColor)'}}>
                                {/* <div className='defaultBannerBG' style={{backgroundImage: `url(${magentaBg_01})`}}> */}

                                    {/* <img src={Logo} className='BannerLogo'/> */}

                                    <div className='stepsBannerRow'>
                                        <img src={carBanner} className='bannerStepsImg'/>
                                        <ChevronRight/>
                                        <img src={partsBanner} className='bannerStepsImg'/>
                                        <ChevronRight/>
                                        <img src={boxGpBanner} className='bannerStepsImg'/>
                                    </div>

                                    <div className='titleContainer'>
                                        <h1><mark>1.</mark> Selecione seu veículo</h1>
                                        <h1><mark>2.</mark> Encontre a peça desejada</h1>
                                        <h1><mark>3.</mark> Compre e receba</h1>
                                    </div>

                                    {/* <h1>Autopeças <mark>novas e origniais</mark> compatíveis com <mark>seu veículo</mark> e enviadas diretamente pelas <mark>concessionárias</mark>.</h1> */}
                                    
                                    {!selectedVersion &&
                                        <div className='startHereContainer'>
                                            <div className='startHere' onClick={openVehicleSelect}>
                                                <KeyboardDoubleArrowUp style={{color: 'var(--secondColor)'}}/>
                                                <span>Comece aqui</span>
                                            </div>
                                        </div>
                                    }
                                    <div className='row center'style={{gap: '10px', marginBottom: '60px'}}>
                                        {!selectedVersion
                                            ?
                                                <Button
                                                    className='giantButtonMain'
                                                    iconStart={<span><AddCar style={{color: 'white', position: 'relative', zIndex: 1}}/></span>}
                                                    label='Selecionar veículo'
                                                    onClick={openVehicleSelect}
                                                />
                                            :
                                                <Button
                                                    className='giantButtonMain'
                                                    iconStart={<span><AddShoppingCart style={{color: 'white', position: 'relative', zIndex: 1}}/></span>}
                                                    label='Peças compatíveis'
                                                    onClick={openVehicleSelect}
                                                />
                                        }
                                        {/* <Button
                                            className='buttonStyle colorButtonMain largeButton'
                                            label='Encontrar peças'
                                        /> */}
                                    </div>


                                    {/* <div className='stepsBannerRow'>
                                        <img src={carBanner} className='bannerStepsImg'/>
                                        <ChevronRight/>
                                        <img src={partsBanner} className='bannerStepsImg'/>
                                        <ChevronRight/>
                                        <img src={boxGpBanner} className='bannerStepsImg'/>
                                    </div> */}

                                </div>
                            </SwiperSlide>
                            
                            <SwiperSlide>
                                <div className='defaultBannerBG' style={{backgroundImage: `url(${partsBg_01})`}}>

                                    <div className='titleContainer'>
                                        <h1>Autopeças <mark>novas e genuínas</mark> de concessionárias de todo o Brasil:</h1>
                                    </div>

                                    <div className='column center' style={{width: '100%'}}>
                                        {/* <h1 style={{padding: '20px'}}>Autopeças novas e genuínas das maiores montadoras e fabricantes homologados:</h1> */}
                                        <div className='row' style={{gap: '6px', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '800px', paddingInline: '5px'}}>
                                            {Object.entries(BrandsListSVG).map(([brandName, brandLogo]) => (
                                                <img className='brandLogosBanner' key={brandName} src={brandLogo} alt={brandName} />
                                            ))}
                                        </div>
                                        <div className='row' style={{columnGap: '20px', flexWrap: 'wrap', justifyContent: 'center', borderTop: '1px solid rgba(255, 255, 255, 0.3)', padding: '20px 0', marginTop: '20px'}}>
                                            {Object.entries(HomologBrandsSVG).map(([brandName, brandLogo]) => (
                                                <img className='brandLogosBannerWide' key={brandName} src={brandLogo} alt={brandName} />
                                            ))}
                                        </div>
                                        <Button
                                            className='buttonColorSecond smallButton'
                                            label={storeInfo.aboutUs.linkCta}
                                            onClick={handleAboutUsClick}
                                        />
                                    </div>

                                    {!selectedVersion &&
                                        <div className='startHereContainer'>
                                            <div className='startHere' onClick={openVehicleSelect}>
                                                <KeyboardDoubleArrowUp style={{color: 'var(--secondColor)'}}/>
                                                <span>Comece aqui</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </SwiperSlide>
                            
                            {/* <SwiperSlide>
                                <div className='defaultBannerBG' style={{backgroundImage: `url(${goparts_logo_01})`}}>

                                    <div className='column center' style={{width: '100%', maxWidth: '800px', gap: '20px'}}>
                                        <div className='titleContainer'>
                                            <h1>{content[0]}</h1>
                                        </div>
                                        <span className='aboutUsBannerSpan'>{content[1]}</span>
                                        <div className='row center' style={{gap: '10px'}}>
                                            <Button
                                                className='buttonColorSecond smallButton'
                                                label={storeInfo.aboutUs.linkCta}
                                                onClick={handleAboutUsClick}
                                            />
                                            <Button
                                                className='buttonColorThird smallButton'
                                                label='Comprar peças'
                                                onClick={openVehicleSelect}
                                            />
                                        </div>
                                    </div>

                                    {!selectedVersion &&
                                        <div className='startHereContainer'>
                                            <div className='startHere' onClick={openVehicleSelect}>
                                                <KeyboardDoubleArrowUp style={{color: 'var(--secondColor)'}}/>
                                                <span>Comece aqui</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </SwiperSlide> */}
                        </>
                    :
                        <>
                            <SwiperSlide>
                                <div className='defaultBannerBG' style={{backgroundImage: `url(${contentData.banner01})`}}>

                                    <div className='titleContainer'>
                                        <h1>Autopeças <mark>novas e genuínas</mark> de concessionárias de todo o Brasil:</h1>
                                    </div>

                                    <div className='column center' style={{width: '100%'}}>
                                        {/* <h1 style={{padding: '20px'}}>Autopeças novas e genuínas das maiores montadoras e fabricantes homologados:</h1> */}
                                        <div className='row' style={{gap: '6px', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '800px', paddingInline: '5px'}}>
                                            {Object.entries(BrandsListSVG).map(([brandName, brandLogo]) => (
                                                <img className='brandLogosBanner' key={brandName} src={brandLogo} alt={brandName} />
                                            ))}
                                        </div>
                                        <div className='row' style={{columnGap: '20px', flexWrap: 'wrap', justifyContent: 'center', borderTop: '1px solid rgba(255, 255, 255, 0.3)', padding: '20px 0', marginTop: '20px'}}>
                                            {Object.entries(HomologBrandsSVG).map(([brandName, brandLogo]) => (
                                                <img className='brandLogosBannerWide' key={brandName} src={brandLogo} alt={brandName} />
                                            ))}
                                        </div>
                                    </div>

                                    {!selectedVersion &&
                                        <div className='startHereContainer'>
                                            <div className='startHere' onClick={openVehicleSelect}>
                                                <KeyboardDoubleArrowUp style={{color: 'var(--secondColor)'}}/>
                                                <span>Comece aqui</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </SwiperSlide>
                            
                            <SwiperSlide>
                                <div className='defaultBannerBG' style={{backgroundImage: `url(${contentData.banner02})`}}>

                                    <div className='titleContainer'>
                                        <h1>Autopeças <mark>novas e genuínas</mark> de concessionárias de todo o Brasil:</h1>
                                    </div>

                                    <div className='column center' style={{width: '100%'}}>
                                        <h1 style={{padding: '20px'}}>Autopeças novas e genuínas das maiores montadoras e fabricantes homologados:</h1>
                                        <div className='row' style={{gap: '6px', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '800px', paddingInline: '5px'}}>
                                            {Object.entries(BrandsListSVG).map(([brandName, brandLogo]) => (
                                                <img className='brandLogosBanner' key={brandName} src={brandLogo} alt={brandName} />
                                            ))}
                                        </div>
                                        <div className='row' style={{columnGap: '20px', flexWrap: 'wrap', justifyContent: 'center', borderTop: '1px solid rgba(255, 255, 255, 0.3)', padding: '20px 0', marginTop: '20px'}}>
                                            {Object.entries(HomologBrandsSVG).map(([brandName, brandLogo]) => (
                                                <img className='brandLogosBannerWide' key={brandName} src={brandLogo} alt={brandName} />
                                            ))}
                                        </div>
                                    </div>

                                    {!selectedVersion &&
                                        <div className='startHereContainer'>
                                            <div className='startHere' onClick={openVehicleSelect}>
                                                <KeyboardDoubleArrowUp style={{color: 'var(--secondColor)'}}/>
                                                <span>Comece aqui</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </SwiperSlide>

                        </>
                }
                
            </Swiper>
        </div>
    );
}

export default BannerSlider;