import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSelectingVehicle } from '../reducers/vehicle';
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YoutubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import BusinessIcon from "@mui/icons-material/Business";
import WhatsappIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import { NavLink } from 'react-router-dom';
import { storeInfo } from '../Utilities/Placeholders';
import { contentData, configData } from '../Whitelabel/websitesData';
import UHD_footer_bg_01 from '../images/UmuaramaHD/UHD_footer_bg_01.jpg';
import Button from '../Common/Button';

function Footer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSelectingVehicle, selectedVersion } = useSelector(state => state.vehicle);
  const [ leadFormName, setLeadFormName ] = useState('');
  const [ leadFormEmail, setLeadFormEmail ] = useState('');

  const openVehicleSelect = () => {
    if (selectedVersion) {
      navigate('/categorias');
    } else {
      dispatch(setIsSelectingVehicle(!isSelectingVehicle));
    }
  };

  const socialLinks = contentData.socialMedia

  const socialIcon = {
    Instagram: InstagramIcon,
    Facebook: FacebookIcon,
    LinkedIn: LinkedInIcon,
    Youtube: YoutubeIcon,
    Twitter: TwitterIcon,
  };  

  const whatsappDefaultLink = `https://api.whatsapp.com/send/?phone=%2B${configData.whatsappNumber}&text=Olá, tudo bem?`;

  const handleWhatsappClick = () => {
      window.open(whatsappDefaultLink, '_blank');
  }
  const handleEmailClick = () => {
      window.open(`mailto:${configData.defaultContactEmail}`, '_blank');
  }

  const leadFormSubmit = (e) => {
    e.preventDefault();
    //console.log('leadForm: ', leadFormName, leadFormEmail);
  }

  return (
    <div className='footerContainer' style={{backgroundImage: configData.defaultStore === 'UmuaramaHD' ? `url(${UHD_footer_bg_01})` : '', paddingTop: configData.defaultStore === 'UmuaramaHD' ? '0': '20px'}}>
      
      {/* {configData.defaultStore === 'UmuaramaHD' &&
        <div className='leadFormContainer'>
          <form className='leadFormRow' onSubmit={leadFormSubmit}>
            <div className='leadFormText'>
              <h2>Fique por dentro das melhores ofertas</h2>
              <span>cadastre seu email e <span style={{ color: 'var(--secondColor)' }}>ganhe 5%</span> na sua próxima compra</span>
            </div>
            <input
              placeholder='Digite seu nome'
              value={leadFormName}
              onChange={(e) => setLeadFormName(e.target.value)}
            />
            <input
              placeholder='Digite seu email'
              value={leadFormEmail}
              onChange={(e) => setLeadFormEmail(e.target.value)}
            />
            <Button
              className='buttonColorThird smallButton'
              label='Cadastrar'
              type='submit'
            />
          </form>
        </div>
      } */}

      <div className="footerMenu">
          <div className='column' style={{gap: '10px'}}>
            <h4>Loja</h4>
            <NavLink to={'/'}>Início</NavLink>
            <NavLink to={'/categorias'}>Comprar</NavLink>
            <NavLink to={'/checkout'}>Carrinho</NavLink>
            <NavLink to={'/minha-conta#pedidos'}>Pedidos</NavLink>
            <NavLink to={'/minha-conta#meus-dados'}>Conta</NavLink>
            <h4>Atendimento</h4>
            <a onClick={handleWhatsappClick} style={{display: 'flex', alignItems: 'center', gap: '5px'}}><WhatsappIcon/>{configData.whatsappNumber}</a>
            <a onClick={handleEmailClick} style={{display: 'flex', alignItems: 'center', gap: '5px'}}><EmailIcon/>{configData.defaultContactEmail}</a>
          </div>

          <div className='column' style={{gap: '10px'}}>
            <h4>Informações</h4>
            <NavLink to={'/sobre-nos'}>Sobre nós</NavLink>
            <NavLink to={'/privacidade'}>Privacidade</NavLink>
            <NavLink to={'/termos-e-condicoes'}>Termos e Condições</NavLink>
            <NavLink to={'/troca-e-devolucao'}>Troca e Devolução</NavLink>
            <NavLink to={'/reembolso'}>Reembolso</NavLink>
          </div>

          <div className='column' style={{gap: '10px'}}>
            <h4>Mais</h4>
            {Object.entries(socialLinks).map(([key, value]) => {
              const IconComponent = socialIcon[key]; // Dynamically get the icon component
              return value ? ( // Check if there is a link
                <a href={value} key={key} target="_blank" rel="noopener noreferrer" style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                  {IconComponent && <IconComponent />}
                  {key}
                </a>
              ) : null;
            })}
          </div>
      </div>
      {contentData.footerContent.length > 0 &&
        <div className='defaultItemBgDark column center' style={{fontSize: '12px', marginInline: '20px', textAlign: 'center', marginBottom: '10px'}}>
          {contentData.footerContent.map((item, index) => (
            <span key={index}>{item}</span>
          ))}
        </div>
      }
      <div className='defaultItemBgDark column center' style={{fontSize: '12px', marginInline: '20px', textAlign: 'center'}}>
        <span>{configData.CNPJ}</span>
        <span>{configData.CNPJaddress}</span>
        <span>® {configData.defaultStore} - {new Date().getFullYear()}</span>
      </div>
    </div>
  );
} 

export default Footer;