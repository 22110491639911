import React, { useState, useEffect } from 'react';
import '../App.css';
import '../Checkout/Checkout.css';
import OrderItem from './OrderItem';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase';
import { useUser } from '../Providers/UserContext';
import { CircularProgress } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

function Pedidos() {
  const [orders, setOrders] = useState([]);
  const {gpuser,setUser} = useUser(); // Access GPUser
  const cancelOrderPicPay = httpsCallable(functions, 'cancelOrderPicPay');
  const [isLoading, setIsLoading] = useState(false);

  const fetchOrders = () => {
    if (!gpuser) return; // Don't run if the user is not loaded

    const ordersRef = collection(firestore, 'orders');
    const q = query(ordersRef, where('user', '==', gpuser.id));

    // Listen to Firestore orders collection in real-time
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const ordersArray = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Sort ordersArray by id (ascending or descending)
      ordersArray.sort((a, b) => Number(b.id) - Number(a.id)); // Ascending order
      setOrders(ordersArray);
    });

    // Clean up the listener on component unmount
    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = fetchOrders(); // Call the fetchOrders function
    return () => unsubscribe();
  }, [gpuser]);

  const preparePaymentData = (order) => {
    const payment = {
      type: order.paymentType || 'Unknown',
      subtotalPrice: (order.price) - order.deliveryPrice || 0,
      discount: order.discount || 0,
      shipping: order.deliveryPrice || 0,
      totalPrice: (order.price) || 0,
      payId: order.payId || null,
      merchantChargeId: order.merchantCodeID || null,
      parcels: order.parcels || null,
      qrcode: order.qrcode || null,
      ...order.payment
    };

    if (!payment.totalPrice) {
      payment.totalPrice = payment.subtotalPrice + payment.shipping - payment.discount;
    }

    return payment;
  };

  if (gpuser === null) {
    return <div>Loading user data...</div>; // Show a loading indicator while rehydrating
  }

  if (gpuser === undefined) {
    return <div>User not authenticated. Please log in.</div>; // Handle the case where no user is logged in
  }

  if (orders.length > 0) {
    var a = orders[0]
    //console.log(a)
  }

  const handleCancelOrder = async (payId, amount, order_id) => {
    const cancelConfirm = window.confirm('Deseja cancelar o pedido Nº ' + order_id + '?');
    if (cancelConfirm) {
      setIsLoading(true);
      // payId = '20403ecc-0158-4d0b-bb18-b04d83178389';
      await cancelOrderPicPay({ payId, amount, order_id });
      setIsLoading(false);
      fetchOrders(); // Refresh the orders after cancellation
    }
  };

  return (
    <div className="fadeIn">
      {/* <div className='pageContentSmall'> */}
      {isLoading ? (
        <div className='column center'>
          <CircularProgress
            style={{ padding: '20px' }}
            sx={{
              color: 'var(--secondColor)',
            }}
          />
        </div>
      ) : (
        <div className='column' style={{width: '100%', gap: '10px'}}>
          {orders.length > 0 ? (
            orders.map(order => (
              <OrderItem
                key={order.id}
                id={order.id}
                status={order.status}
                date={order.date}
                address={order.address}
                payment={preparePaymentData(order)}
                shipping={order.shipping}
                discount={order.discount}
                items={order.items}
                shippingIDs={order.shippingIDs ? order.shippingIDs : null}
                onCancelOrder={handleCancelOrder} // Pass the function as a prop
              />
            ))
          ) : (
            <div className='column center'>
              <CircularProgress
                style={{ padding: '20px' }}
                sx={{
                  color: 'var(--secondColor)',
                }}
              />
              <span>Carregando seus pedidos...</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Pedidos;