import React from 'react';
import '../App.css';
import './TabSelector.css';

const TabSelector = ({ tabs, selectedTab, onTabSelect }) => {
  return (
    <div className="tabSelector">
      {tabs.map(tab => (
        <div
          key={tab}
          className={`tabButton ${selectedTab === tab ? 'active' : ''}`}
          onClick={() => onTabSelect(tab)}
        >
          <h2>{tab}
            {/* {tab === 'Serviços' && <mark>Novo!</mark>} */}
          </h2>

        </div>
      ))}
    </div>
  );
};

export default TabSelector;